import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  InitConfig,
  RunContextApp,
  RunState,
  RUN_MODE,
  FeatureFlags,
} from "RunContext/RCApp";
import App from "./src/App";
import * as Sentry from "@sentry/react";

const env = process.env.NODE_ENV;
console.log("env from init", env);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: env,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1,
  // Session Replay
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
  // Additional recommended settings
  beforeSend(event) {
    if (env !== 'production') return null;
    return event;
  },
  enabled: env === 'production',
});

const defaultFlags: FeatureFlags = {
  summaryRequired: false,
  realtimeFeedback: false,
};
const runMode = RUN_MODE.DEV;
//   process.env.NODE_ENV === "production" ? RUN_MODE.PROD : RUN_MODE.DEV;
// const logLevel =
//   process.env.NODE_ENV === "production" ? LOG_LEVEL.NONE : LOG_LEVEL.DEBUG;

const ic = new InitConfig(runMode, true, defaultFlags);
const rs = new RunState();
const rc = new RunContextApp(ic, rs);

rc.init();

const rootDiv = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootDiv);

root.render(<App rc={rc} />);
