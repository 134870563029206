import React, { useEffect, useState, Suspense, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { graphql, useLazyLoadQuery, PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { performanceHeaders } from "../../utils/labels";
import { Button, Typography } from "@mui/material";
import ApiClient from "../../RunContext/ApiClient/apiClient";
import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";
import MetricsCard from "../Clincian/MetricsCard";

const AllMeetingMetricsQuery = graphql`
  query ManagerDashboardQuery($input: MeetingMetricsNewInput!) {
    allMeetingMetricsNew(input: $input) {
      meetingId
      userId
      orgId
      metrics
      email
    }
  }
`;
const ManagerDashboardMetricMetadataQuery = graphql`
  query ManagerDashboardMetricMetadataQuery {
    metricsMetadata {
      metricName
      benchmarkScore
      comparator
      showTooltip
      metricType
      maxScore
    }
  }
`;


function ManagerDashboardInner({
  queryReference,
  filter,
  setFilter
}: {
  queryReference: PreloadedQuery<any>;
  filter: string;
  setFilter: (filter: string) => void;
}) {
  const [rowData, setRowData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [uniqueKeys, setUniqueKeys] = useState<Set<string>>(new Set());
  const [isExporting, setIsExporting] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const metricMetadata = useLazyLoadQuery<any>(
    ManagerDashboardMetricMetadataQuery,
    {}
  );
  const { rc } = useGlobalConfig();

  const data = usePreloadedQuery(AllMeetingMetricsQuery, queryReference);

  const toggleFilter = () => {
    const newFilter = filter === "org" ? "manager" : "org";
    setFilter(newFilter);
  };

  useEffect(() => {
    console.log("Data received:", data);
    if (data && (data as any).allMeetingMetricsNew) {
      try {
        const formattedData = (data as any).allMeetingMetricsNew.map((item: any) => {
          const metricsStr = item.metrics
            .replace(/'/g, '"')
            .replace(/"\s*([^"]+)\s*":/g, '"$1":');
          const metrics = JSON.parse(metricsStr);
          console.log("Metrics:", metrics);

          // Update uniqueKeys set
          setUniqueKeys(prevKeys => {
            const newKeys = new Set(prevKeys);
            Object.keys(metrics).forEach(key => newKeys.add(key));
            return newKeys;
          });

          return {
            userId: item.userId,
            email: item.email,
            ...Object.fromEntries(
              Object.entries(metrics).map(([key, value]: [string, any]) => [
                key,
                {
                  score: parseFloat(value.score),
                  min_score: parseFloat(value.min_score),
                  max_score: parseFloat(value.max_score)
                }
              ])
            )
          };
        });
        setRowData(formattedData);
        setSelectedRows(formattedData);
      } catch (err) {
        console.error("Error formatting data:", err);
        setError("Error formatting data. Check console for details.");
      }
    } else {
      console.log("No data received or data in unexpected format");
      setError("No data received or data in unexpected format");
    }
  }, [data]);

  const columnDefs = useMemo(() => {
    if (rowData.length === 0) return [];

    return [
      {
        headerName: "Email",
        field: "email",
        cellRenderer: (params: any) => {
          return <strong>{params.value}</strong>;
        },
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      ...Array.from(uniqueKeys)
        .filter(key => key in performanceHeaders)
        .sort((a, b) => {
          const posA = performanceHeaders[a as keyof typeof performanceHeaders]?.columnPosition ?? Infinity;
          const posB = performanceHeaders[b as keyof typeof performanceHeaders]?.columnPosition ?? Infinity;
          return posA - posB;
        })
        .map((key) => ({
          headerName: performanceHeaders[key as keyof typeof performanceHeaders].title,
          field: key,
          valueFormatter: (params: { data?: Record<string, any> }) => {
            const value = params.data ? params.data[key] : null;
            if (typeof value === 'object' && value !== null && 'score' in value) {
              return `${value.score}`;
            }
            return value;
          },
          tooltipValueGetter: (params: any) => {
            const value = params.data[key];
            if (typeof value === 'object' && value !== null && 'score' in value) {
              return `Min: ${value.min_score}, Max: ${value.max_score}`;
            }
            return null;
          }
        }))
    ];
  }, [rowData, uniqueKeys]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (rowData.length === 0) {
    return <div>Loading... (If this persists, there might be no data available)</div>;
  }

  const handleExportExcel = async () => {
    const token = localStorage.getItem("access_token");
    try {
      setIsExporting(true);
      const apiClient = ApiClient.getInstance(rc);
      const response = await apiClient.post(
        `${process.env.REACT_APP_BACKEND_QUERY_SERVICES}/get-excel?filter=${filter}`,
        {},  // empty body for POST request
        {
          responseType: 'arraybuffer',  // Changed from 'blob' to 'arraybuffer'
          headers: {
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      // Create blob from array buffer
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      // Get filename from headers or use default
      const contentDisposition = response.headers?.['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'data.xlsx';

      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting Excel:", error);
      // Add user feedback here (e.g., toast notification)
    } finally {
      setIsExporting(false);
    }
  };

  const calculateAverageMetrics = (rows: any[]) => {
    if (!rows.length) return null;

    const result: { [key: string]: any } = {};

    Array.from(uniqueKeys)
      .filter(key => key in performanceHeaders)
      .forEach(key => {
        console.log(`Processing ${key}:`, rows.map(row => row[key]));

        const scores = rows
          .map(row => {
            const value = row[key];
            return typeof value === 'object' && value !== null ? value.score : null;
          })
          .filter(score => score !== null && !isNaN(score));

        if (scores.length > 0) {
          const avgScore = scores.reduce((a, b) => a + b, 0) / scores.length;
          console.log(`${key} scores:`, scores, 'average:', avgScore);

          result[key] = {
            score: avgScore,
            min_score: Math.min(...scores),
            max_score: Math.max(...scores)
          };
        }
      });

    console.log('Final averaged metrics:', result);
    return result;
  };

  const onSelectionChanged = (event: any) => {
    console.log("onSelectionChanged", event);
    if (event.source === "rowDataChanged") {
      ;
    }
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node: any) => node.data);
    setSelectedRows(selectedData);
    setSelectedRow(selectedData.length === 1 ? selectedData[0] : null);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <Typography variant="h5">Team Overview</Typography>
        <div className="flex gap-4 items-center">
          <Button
            variant="outlined"
            onClick={toggleFilter}
            color="primary"
          >
            Switch to: {filter === "org" ? "Manager" : "Organization"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isExporting}
            onClick={handleExportExcel}
          >
            Export Excel
          </Button>
        </div>
      </div>

      {selectedRows.length > 0 && (
        <div className="mt-4">
          <div className="flex justify-between items-center mb-4">
            <Typography variant="h6">
              {selectedRows.length === 1
                ? `Metrics for ${selectedRows[0].email}`
                : `Average Metrics for ${selectedRows.length} Selected Users`
              }
            </Typography>
            {/* <Button
              size="small"
              onClick={() => {
                setSelectedRows([]);
                setSelectedRow(null);
              }}
              variant="outlined"
            >
              Clear Selection
            </Button> */}
          </div>
          <div className="grid-cols-3 grid gap-2 grow h-fit">
            {(() => {
              // If single row selected, use its data directly
              // Otherwise calculate averages
              const metricsData = selectedRows.length === 1
                ? selectedRows[0]
                : calculateAverageMetrics(selectedRows);

              return Array.from(uniqueKeys)
                .filter(key => key in performanceHeaders)
                .map((key) => {
                  const metricData = selectedRows.length === 1
                    ? metricsData[key]
                    : metricsData?.[key];

                  const metadata = metricMetadata?.metricsMetadata?.find(
                    (m: any) => m.metricName === performanceHeaders[key as keyof typeof performanceHeaders].title
                  );

                  if (!metricData) {
                    console.log(`No metric data for ${key}`);
                    return null;
                  }

                  console.log(`Rendering metric card for ${key}:`, {
                    metricData,
                    metadata
                  });

                  return (
                    performanceHeaders[key as keyof typeof performanceHeaders].columnPosition > 4 && <MetricsCard
                      key={key}
                      metricDetails={{
                        metricName: performanceHeaders[key as keyof typeof performanceHeaders].title,
                        clinicianScore: selectedRows.length === 1 ? metricData.score : metricData.score
                      }}
                      metadata={metadata}
                    />
                  );
                });
            })()}
          </div>
        </div>
      )}

      <div className="ag-theme-quartz mt-4" style={{ height: 500 }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          domLayout='autoHeight'
          animateRows={true}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          rowSelection="multiple"
          onSelectionChanged={onSelectionChanged}
          defaultColDef={{
            sortable: true,
            filter: true
          }}
          suppressRowClickSelection={true}
          rowMultiSelectWithClick={true}
          isRowSelectable={() => true}
          onGridReady={(params) => {
            params.api.selectAll();
          }}
          onRowDataUpdated={(params) => {
            console.log("onRowDataUpdated", params);
            params.api.selectAll();
          }}
        />
      </div>
    </div>
  );
}

// function MetricsCard(props: any) {
//   const { metadata } = props;
//   const { metricName, clinicianScore } = props?.metricDetails;
//   return <div>MetricsCard</div>;
// }

const ManagerDashboard = () => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(AllMeetingMetricsQuery);
  const [filter, setFilter] = useState<string>("manager");

  useEffect(() => {
    loadQuery({ input: { filter } });
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery, filter]);

  if (!queryReference) {
    return <div>Loading...</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ManagerDashboardInner
        queryReference={queryReference}
        filter={filter}
        setFilter={setFilter}
      />
    </Suspense>
  );
};

export default ManagerDashboard;
