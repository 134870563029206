import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";
import { USER_ROLES } from "RunContext/session/sessionManager";

export const useAuth = () => {
  const { rc } = useGlobalConfig();
  const isAuthenticated = rc.session.getLoggedIn();
  const role = rc.session.getRole();

  const isClinician = role === USER_ROLES.CLINICIAN;

  return {
    isAuthenticated,
    role,
    isClinician,
  };
};