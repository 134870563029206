import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CDN_URL } from "utils/constants";
import { FaQuestionCircle } from "react-icons/fa";
import { useAuth } from "hooks/useAuth";
import { useGlobalConfig } from "RunContext/context-creation/GlobalConfigContext";
import Typography from "@mui/material/Typography";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  MenuItem,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { FaChevronDown } from "react-icons/fa";
import faqContent from "__mock__/faqContent";
import { Button } from "@/components/ui/button";
import { useAuth0 } from "@auth0/auth0-react";

const StyledNav = ({ to, children }: any) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `text-gray-400 flex items-center w-40 ${isActive && "text-white"}`
      }
    >
      {children}
    </NavLink>
  );
};

interface HeaderProps {
  menuItems?: any[];
  showFaq: boolean;
  showLogout?: boolean;
}
const Header = ({ menuItems, showFaq, showLogout = true }: HeaderProps) => {
  const { isClinician } = useAuth();
  const { logout } = useAuth0();

  const { rc } = useGlobalConfig();
  const username = rc.session.getUserName();
  const to = isClinician ? "/coach/calls" : "/manager";
  const [faqOpen, setFaqOpen] = useState(false);
  const [expanded, setExpanded] = useState<any>(null);
  const [expandedFaqType, setExpandedFaqType] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAvatarMenuClose = () => {
    setAnchorEl(null);
  };
  const orgId = rc.session.getOrgId();
  const faqContentToDisplay = faqContent[(orgId || "default") as keyof typeof faqContent];
  // console.log(faqContentToDisplay);

  const handleLogout = () => {
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
  };

  return (
    <>
      <nav className="w-full h-16 flex px-11 justify-between items-center border-b-2 border-secondary sticky top-0 z-10 bg-navBackground">
        <StyledNav to={to}>
          <img
            src={"https://" + CDN_URL + "/assets/logo-white.png"}
            className="w-24 mr-28"
            alt="hale ai"
          />
        </StyledNav>
        <div className="flex items-center justify-between">
          {menuItems?.map((menu, idx) => (
            <StyledNav to={menu.to} className="mr-2" key={idx}>
              {menu.icon} {menu.name}
            </StyledNav>
          ))}

          {showFaq && (
            <div
              className="flex text-gray-400 items-center cursor-pointer"
              onClick={() => setFaqOpen(true)}
            >
              <FaQuestionCircle className="mr-2" />{" "}
              <Typography>FAQs</Typography>
            </div>
          )}
        </div>
        {showLogout && (
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleAvatarClick}
            className="bg-purple-50 text-center rounded-full w-8 h-8 ml-9 text-black p-1 hover:bg-purple-50 cursor-pointer"
          >
            {username?.charAt(0)}
          </Button>)}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleAvatarMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </Menu>
      </nav>
      <Dialog
        open={faqOpen}
        onClose={() => setFaqOpen(false)}
        className="flex justify-center"
      >
        <DialogTitle>
          <Typography variant="h5">FAQs</Typography>
        </DialogTitle>
        <DialogContent>
          <ul>
            {faqContentToDisplay?.map((item) => (
              <Accordion
                key={item.id}
                className="mb-2"
                expanded={expandedFaqType === item.title}
                onChange={() =>
                  setExpandedFaqType((current: any) =>
                    current === item.title ? null : item.title
                  )
                }
              >
                <AccordionSummary
                  expandIcon={<FaChevronDown className="text-white" />}
                  sx={{
                    backgroundColor: "#253551",
                    color: "white",
                  }}
                >
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {item.questions.map((itemQuestions) => (
                    <Accordion
                      key={itemQuestions.id}
                      expanded={expanded === itemQuestions.id}
                      onChange={() =>
                        setExpanded((curr: any) =>
                          curr === itemQuestions.id ? null : itemQuestions.id
                        )
                      }
                    >
                      <AccordionSummary
                        expandIcon={<FaChevronDown />}
                        sx={{
                          backgroundColor: "rgb(229 231 235);",
                        }}
                      >
                        {" "}
                        <Typography>{itemQuestions.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{itemQuestions.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </ul>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Header;
