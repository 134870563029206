import React, { Suspense, lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PublicPage from "./components/PublicPage/PublicPage";
import Error from "./components/PublicPage/Error";
import ProtectedComponent from "./components/Shared/ProtectedComponent";
import ManagerDashboard from "./components/Manager/ManagerDashboard";
import { AuthenticationGuard } from "./components/Auth/AuthenticationGuard";

const SSO = lazy(() => import("./components/SSO"));
const Clinician = lazy(() => import("./components/Clincian/Clinician"));
const ClinicianInsights = lazy(
  () => import("./components/Clincian/ClinicianInsights")
);
const Calls = lazy(() => import("./components/Clincian/Calls"));
const Manager = lazy(() => import("./components/Manager/Manager"));
const Clinicians = lazy(() => import("./components/Manager/MyClinicians"));
const ManagerInsights = lazy(
  () => import("./components/Manager/ManagerInsights")
);
const AllTrainings = lazy(
  () => import("./components/Clincian/Tranings/AllTrainings")
);
const TrainingPage = lazy(
  () => import("./components/Clincian/Tranings/TrainingPage")
);
const TrainingOverview = lazy(
  () => import("./components/Manager/Training/TrainingOverview")
);
const Reports = lazy(() => import("./components/Manager/Recruiter/Reports"));
import Saphire from "./components/Saphire";
import { Skeleton } from "@mui/material";
import SimUser from "./components/Manager/SimUser";
import { Redirect } from "./components/PublicPage/Redirect";
import { Status } from "./components/PublicPage/Status";
import Admin from "./components/PublicPage/Admin";
const Recruiter = lazy(() => import("./components/Manager/Recruiter"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicPage />,
    errorElement: <Error />,
  },
  {
    path: "/userLogin",
    element: <SSO />,
    errorElement: <Error />,
  },
  {
    path: "/cloathCliff",
    element: <Saphire />,
    errorElement: <Error />,
  },
  {
    path: "/auth/callback",
    element: <Redirect />,
    errorElement: <Error />,
  },
  {
    path: "/coach",
    element: (
      <ProtectedComponent
        component={Clinician}
        requestingRole={["CLINICIAN", "SIMUSER"]}
      />
    ),
    children: [
      {
        path: "calls",
        element: <Calls />,
        errorElement: <Error />,
      },
      {
        path: "insights",
        element: <ClinicianInsights />,
        errorElement: <Error />,
      },
      {
        path: "trainings",
        errorElement: <Error />,
        children: [
          {
            path: "",
            element: <AllTrainings />,
          },
          {
            path: ":id",
            element: <TrainingPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/manager",
    element: (
      <ProtectedComponent
        component={Manager}
        requestingRole={["MANAGER"]}
      />
    ),
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: (
          <Suspense
            fallback={
              <div className="flex flex-col gap-1">
                <Skeleton height={100} />
                <Skeleton height={500} />
              </div>
            }
          >
            <Recruiter />
          </Suspense>
        ),
      },
      {
        path: "reports",
        element: (
          <Suspense>
            <Reports />
          </Suspense>
        ),
      },
      {
        path: "clinicians",
        element: <Clinicians />,
        errorElement: <Error />,
      },
      {
        path: "insights",
        element: <ManagerInsights />,
        errorElement: <Error />,
      },
      {
        path: "trainings",
        errorElement: <Error />,
        children: [{ path: "", element: <TrainingOverview /> }],
      },
      {
        path: "dashboard",
        element: <ManagerDashboard />,
        errorElement: <Error />,
      },
    ],
  },
  {
    path: "/admin",
    element: <ProtectedComponent component={Admin} requestingRole={["ADMIN"]} />,
    errorElement: <Error />,
  },
  {
    path: "/status",
    element: <Status />,
    errorElement: <Error />,
  },
]);

export const Pages = () => {
  return <RouterProvider router={router} />;
};
