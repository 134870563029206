import React, { useState } from 'react';
import { Box, Typography, Paper, Tabs, Tab, Divider, Button, TextField, Chip } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Header from 'Shared/Header';

const ApiDocumentation: React.FC = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [copied, setCopied] = useState(false);
    const [copiedOrgId, setCopiedOrgId] = useState(false);

    // Get the backend API URL from environment variables
    const apiUrl = process.env.REACT_APP_BACKEND_QUERY_SERVICES || 'http://localhost:8000';

    // Get the actual access token from Auth0 storage
    const getAccessToken = () => {
        // Check different possible storage locations
        const token = localStorage.getItem('access_token')

        // You may need to parse JSON in some cases if token is stored in a JSON object
        // For example:
        // const authData = JSON.parse(localStorage.getItem('auth0'));
        // return authData?.access_token;

        return token || 'Your access token was not found. Please login again.';
    };

    const accessToken = getAccessToken();
    const orgId = "org_dOEtuJrkizhCqiWZ"; // This would ideally come from your auth or context

    const handleCopyToken = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleCopyOrgId = () => {
        setCopiedOrgId(true);
        setTimeout(() => setCopiedOrgId(false), 2000);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const curlExample = (endpoint: string, method: string, body?: string) => {
        let curl = `curl --request ${method} \\\n`;
        curl += `  --url '${apiUrl}${endpoint}' \\\n`;
        curl += `  --header 'Authorization: Bearer YOUR_ACCESS_TOKEN'`;

        if (body) {
            curl += ` \\\n  --header 'Content-Type: application/json' \\\n`;
            curl += `  --data '${body}'`;
        }

        return curl;
    };

    return (
        <Paper sx={{ p: 3, mb: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h4">Organization API Documentation</Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <TextField
                            size="small"
                            label="Your Access Token"
                            value={accessToken}
                            sx={{ width: 300, mr: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <CopyToClipboard text={accessToken} onCopy={handleCopyToken}>
                            <Button variant="contained" size="small">
                                {copied ? 'Copied!' : 'Copy Token'}
                            </Button>
                        </CopyToClipboard>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <TextField
                            size="small"
                            label="Your Organization ID"
                            value={orgId}
                            sx={{ width: 300, mr: 1 }}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <CopyToClipboard text={orgId} onCopy={handleCopyOrgId}>
                            <Button variant="contained" size="small">
                                {copiedOrgId ? 'Copied!' : 'Copy Org ID'}
                            </Button>
                        </CopyToClipboard>
                    </Box>
                </Box>
                {/* 
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                   
                </Box> */}
            </Box>


            <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
                Use this token in your API requests by adding the header: <code>Authorization: Bearer {accessToken.substring(0, 15)}...</code>
            </Typography>

            <Divider sx={{ mb: 3 }} />

            <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 2 }}>
                <Tab label="Instructions" />
                <Tab label="Invite Users" />
                <Tab label="Members" />
                <Tab label="Roles" />
            </Tabs>

            {activeTab === 0 && (
                <Box>
                    <Typography variant="h5" gutterBottom>Common API Tasks</Typography>
                    <Typography paragraph>
                        Below are instructions for common administrative tasks.
                    </Typography>

                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 3fr',
                            borderBottom: 1,
                            borderColor: 'divider',
                            backgroundColor: '#f5f5f5',
                            p: 2,
                            fontWeight: 'bold'
                        }}>
                            <Typography variant="subtitle1">Action</Typography>
                            <Typography variant="subtitle1">Instruction</Typography>
                        </Box>

                        {/* Invite user row */}
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 3fr',
                            p: 2,
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}>
                            <Typography variant="body1">Invite new user</Typography>
                            <Typography variant="body1">
                                Use the <code>POST /organizations/{'{org_id}'}/invitations</code> API to add a brand new user to the platform
                            </Typography>
                        </Box>

                        {/* Assign role row */}
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 3fr',
                            p: 2,
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}>
                            <Typography variant="body1">Assigning a role to a user after adding them</Typography>
                            <Typography variant="body1">
                                Use <code>/organizations/{'{org_id}'}/members/{'{user_id}'}/roles</code> API to assign either the "MANAGER" or "USER" role.
                                <br />
                                <strong>Note:</strong> you have to assign a role to a user
                            </Typography>
                        </Box>

                        {/* Change role row */}
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 3fr',
                            p: 2,
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}>
                            <Typography variant="body1">Changing someone's role</Typography>
                            <Typography variant="body1">
                                First, delete their current role using <code>DELETE /organizations/{'{org_id}'}/members/{'{user_id}'}/roles</code> API.
                                <br />
                                Then, assign them a new role using <code>POST /organizations/{'{org_id}'}/members/{'{user_id}'}/roles</code> API
                            </Typography>
                        </Box>

                        {/* Delete user row */}
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 3fr',
                            p: 2,
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}>
                            <Typography variant="body1">Deleting a user</Typography>
                            <Typography variant="body1">
                                Use the <code>DELETE /organizations/{'{org_id}'}/members/{'{user_id}'}</code> API
                            </Typography>
                        </Box>

                        {/* Get users row */}
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 3fr',
                            p: 2
                        }}>
                            <Typography variant="body1">Get the details of every user in your organization</Typography>
                            <Typography variant="body1">
                                Use <code>GET /organizations/{'{org_id}'}/members</code> API to see email, user id, name,
                                and role (USER or MANAGER) of every user in your organization who is on the Hale AI platform
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
            )}

            {activeTab === 1 && (
                <Box>
                    <Typography variant="h5" gutterBottom>Invite a User</Typography>
                    <Typography paragraph>
                        Send an invitation to a user to join an organization.
                    </Typography>

                    <Typography variant="subtitle1">Endpoint</Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', p: 1, borderRadius: 1, mb: 2 }}>
                        <code>POST /organizations/{'{org_id}'}/invitations</code>
                    </Box>

                    <Typography variant="subtitle1">Request Body</Typography>
                    <SyntaxHighlighter language="json" style={docco}>
                        {`{
  "inviter": {
    "name": "Admin User"  // This is you (the person sending the invitation)
  },
  "invitee": {
    "email": "user@example.com"  // The person you're inviting to the platform
  },
  "roles": "ROLE", //  "SIMUSER","MANAGER"
  // SIMUSER - A user who will do the role plays, and is not a manager (e.g., a nurse)
  // MANAGER - A user who will assign role plays to their teams of nurses
  "send_invitation_email": true,
}`}
                    </SyntaxHighlighter>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Curl Example</Typography>
                    <SyntaxHighlighter language="bash" style={docco}>
                        {curlExample('/organizations/{org_id}/invitations', 'POST', `{
  "inviter": {
    "name": "Admin User"  // This is you (the person sending the invitation)
  },
  "invitee": {
    "email": "user@example.com"  // The person you're inviting to the platform
  },
  "roles": "ROLE", //  "SIMUSER","MANAGER"
  // SIMUSER - A user who will do the role plays, and is not a manager (e.g., a nurse)
  // MANAGER - A user who will assign role plays to their teams of nurses
  "send_invitation_email": true,
}`)}
                    </SyntaxHighlighter>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Response</Typography>
                    <SyntaxHighlighter language="json" style={docco}>
                        {`{
  "status": "success",
  "invitations": {
    "id": "inv_123",
    "organization_id": "{org_id}",
    "inviter": {
      "name": "Admin User"  // The person who sent the invitation
    },
    "invitee": {
      "email": "user@example.com"  // The person who was invited
    },
    "invitation_url": "https://YOUR_DOMAIN/login?invitation=inv_123&organization={org_id}&organization_name=Your%20Organization",
    "created_at": "2023-01-01T00:00:00.000Z",
    "expires_at": "2023-01-08T00:00:00.000Z",
    "roles": "ROLE" //  "SIMUSER","MANAGER"
    // SIMUSER - A user who will do the role plays, and is not a manager (e.g., a nurse)
    // MANAGER - A user who will assign role plays to their teams of nurses
  }
}`}
                    </SyntaxHighlighter>
                </Box>
            )}

            {activeTab === 2 && (
                <Box>
                    <Typography variant="h5" gutterBottom>Get Organization Members</Typography>
                    <Typography paragraph>
                        Retrieve a list of members in an organization.
                    </Typography>

                    <Typography variant="subtitle1">Endpoint</Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', p: 1, borderRadius: 1, mb: 2 }}>
                        <code>GET /organizations/{'{org_id}'}/members</code>
                    </Box>

                    <Typography variant="subtitle1">Query Parameters</Typography>
                    <Typography paragraph>
                        <code>page</code>: Page number (default: 0)<br />
                        <code>per_page</code>: Items per page (default: 25, max: 100)
                    </Typography>

                    <Typography variant="subtitle1">Curl Example</Typography>
                    <SyntaxHighlighter language="bash" style={docco}>
                        {curlExample('/organizations/{org_id}/members?page=0&per_page=25', 'GET')}
                    </SyntaxHighlighter>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Response</Typography>
                    <SyntaxHighlighter language="json" style={docco}>
                        {`[
  {
    "user_id": "{user_id}",
    "email": "user1@example.com",
    "name": "User One",
    "roles": "ROLE" //  "SIMUSER","MANAGER"
    // SIMUSER - A user who will do the role plays, and is not a manager (e.g., a nurse)
    // MANAGER - A user who will assign role plays to their teams of nurses
  },
  {
    "user_id": "{user_id}",
    "email": "user2@example.com",
    "name": "User Two",
    "roles": "ROLE" //  "SIMUSER","MANAGER"
  }
]`}
                    </SyntaxHighlighter>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h5" gutterBottom>Remove a Member</Typography>
                    <Typography paragraph>
                        Remove a member from an organization.
                    </Typography>

                    <Typography variant="subtitle1">Endpoint</Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', p: 1, borderRadius: 1, mb: 2 }}>
                        <code>DELETE /organizations/{'{org_id}'}/members/{'{user_id}'}</code>
                    </Box>

                    <Typography variant="subtitle1">Curl Example</Typography>
                    <SyntaxHighlighter language="bash" style={docco}>
                        {curlExample('/organizations/{org_id}/members/{user_id}', 'DELETE')}
                    </SyntaxHighlighter>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Response</Typography>
                    <SyntaxHighlighter language="json" style={docco}>
                        {`{
  "status": "success",
  "message": "User removed from organization"
}`}
                    </SyntaxHighlighter>
                </Box>
            )}

            {activeTab === 3 && (
                <Box>
                    <Typography variant="h5" gutterBottom>Assign Roles to a new member</Typography>
                    <Typography paragraph>
                        Assign roles to an organization member.
                    </Typography>

                    <Typography variant="subtitle1">Endpoint</Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', p: 1, borderRadius: 1, mb: 2 }}>
                        <code>POST /organizations/{'{org_id}'}/members/{'{user_id}'}/roles</code>
                    </Box>

                    <Typography variant="subtitle1">Request Body</Typography>
                    <SyntaxHighlighter language="json" style={docco}>
                        {`{
  "roles": "ROLE" //  "SIMUSER","MANAGER"
  // SIMUSER - A user who will do the role plays, and is not a manager (e.g., a nurse)
  // MANAGER - A user who will assign role plays to their teams of nurses
}`}
                    </SyntaxHighlighter>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Curl Example</Typography>
                    <SyntaxHighlighter language="bash" style={docco}>
                        {curlExample('/organizations/{org_id}/members/{user_id}/roles', 'POST', `{
  "roles": "ROLE" //  "SIMUSER","MANAGER"
  // SIMUSER - A user who will do the role plays, and is not a manager (e.g., a nurse)
  // MANAGER - A user who will assign role plays to their teams of nurses
}`)}
                    </SyntaxHighlighter>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Response</Typography>
                    <SyntaxHighlighter language="json" style={docco}>
                        {`{
  "status": "success",
  "message": "Roles assigned to user"
}`}
                    </SyntaxHighlighter>

                    <Divider sx={{ my: 3 }} />

                    <Typography variant="h5" gutterBottom>Remove Roles from a Member</Typography>
                    <Typography paragraph>
                        Remove roles from an organization member.
                    </Typography>

                    <Typography variant="subtitle1">Endpoint</Typography>
                    <Box sx={{ backgroundColor: '#f5f5f5', p: 1, borderRadius: 1, mb: 2 }}>
                        <code>DELETE /organizations/{'{org_id}'}/members/{'{user_id}'}/roles</code>
                    </Box>

                    <Typography variant="subtitle1">Request Body</Typography>
                    <SyntaxHighlighter language="json" style={docco}>
                        {`{
  "roles": "ROLE" //  "SIMUSER","MANAGER"
  // SIMUSER - A user who will do the role plays, and is not a manager (e.g., a nurse)
  // MANAGER - A user who will assign role plays to their teams of nurses
}`}
                    </SyntaxHighlighter>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Curl Example</Typography>
                    <SyntaxHighlighter language="bash" style={docco}>
                        {curlExample('/organizations/{org_id}/members/{user_id}/roles', 'DELETE', `{
  "roles": "ROLE" //  "SIMUSER","MANAGER"
  // SIMUSER - A user who will do the role plays, and is not a manager (e.g., a nurse)
  // MANAGER - A user who will assign role plays to their teams of nurses
}`)}
                    </SyntaxHighlighter>

                    <Typography variant="subtitle1" sx={{ mt: 2 }}>Response</Typography>
                    <SyntaxHighlighter language="json" style={docco}>
                        {`{
  "status": "success",
  "message": "Roles removed from user"
}`}
                    </SyntaxHighlighter>
                </Box>
            )}
        </Paper>
    );
};

// Main Admin component
const Admin: React.FC = () => {

    return (
        <Box>
            <Header menuItems={[]} showFaq={false} showLogout={true} />
            <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
                <Typography variant="h3" gutterBottom>Admin Dashboard</Typography>



                <ApiDocumentation />

                {/* Your other admin components would go here */}
            </Box>
        </Box>
    );
};

export default Admin;
