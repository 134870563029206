import uuidv4 from "./common";

const faqContent = 
{
  default: [
    {
      id: uuidv4(),
      title: "Feedback and assessment report",
      questions: [
        {
          question: "How is the empowerment score calculated?",
          answer:
            "The Empowerment scale evaluates a pharmacist’s recognition of the patient’s expertise in change, rewarding equal partnership and penalizing pharmacist-dominated interactions. A rating of 1 is given when the pharmacist actively assumes the expert role for the majority of the interaction with the patient. Collaboration or empowerment is absent. A rating of 5 is given when the pharmacist actively fosters and encourages power sharing in the interaction in such a way that the patient's contributions substantially influence the nature of the session.",
          id: uuidv4(),
        },
        {
          question: "How is the empathy score calculated?",
          answer:
            "The Empathy scale assesses how well a pharmacist understands and conveys the patient’s perspective, focusing on empathy distinct from sympathy or support. A rating of 1 is given when the pharmacist gives little or no attention to the patient’s perspective. A rating of 5 is given when  the pharmacist shows evidence of deep understanding of the patient’s point of view, not just for what has been explicitly stated but what the patient means but has not yet said.",
          id: uuidv4(),
        },
        {
          question: "What is the purpose of the ‘empirical questioning’ metrics?",
          answer:
            "The purpose of these metrics is to evaluate how effectively the user asked relevant and thorough questions. This helps determine the extent to which the user was able to understand the challenges faced by the patient. Additionally, these metrics assess whether the questions asked were in accordance with the established training guidelines.",
          id: uuidv4(),
        },
        {
          question:
            "What is the purpose of the ‘evidence-based interventions’ metrics?",
          answer:
            "The 'evidence-based interventions' metrics are designed to evaluate whether the user's diagnosis, actions, and prescriptions for the patient were accurate and aligned with the established guidelines.",
          id: uuidv4(),
        },
      ],
    },
    {
      id: uuidv4(),
      title: "Scores",
      questions: [
        {
          question: "What is the purpose of the insights page?",
          answer:
            "The Insights page provides a graphical representation of how your performance has changed over time. It displays trends in your scores on various standard metrics applicable to each simulation.",
          id: uuidv4(),
        },
        {
          question: "How do I know how well I am doing compared to others?",
          answer:
            "On the main landing page, you'll find your average score displayed. Directly beneath your score, look for the figure labeled `Cohort Average.` This figure represents the average score of all users participating in this training program, allowing you to compare your performance directly with others.",
          id: uuidv4(),
        },
        {
          question:
            "What should I do if I notice inaccuracies in my assessment scores?",
          answer:
            "Please note that while our AI-based tool provides assessments on various qualitative and quantitative metrics, there is a very small probability of minor inaccuracies due to drift driven by continuous improvement in our models. The primary aim of this assessment is to evaluate your performance over the long term. Over time, these scores offer an accurate reflection of your performance compared to the cohort. Additionally, the entire cohort of pharmacists going through simulations is being measured using the same methodology, ensuring consistency.",
          id: uuidv4(),
        },
      ],
    },
    {
      id: uuidv4(),
      title: "Navigating the Hale AI dashboard",
      questions: [
        {
          question: "Where can I see my performance trends over time?",
          answer:
            "Click on the ‘Insights’ tab on the top of your screen to access your timeline view.",
          id: uuidv4(),
        },
      ],
    },
    {
      id: uuidv4(),
      title: "Feedback",
      questions: [
        {
          question: "How do I give feedback?",
          answer:
            "Click on the button labeled ‘feedback’ on the top right of the page.",
          id: uuidv4(),
        },
      ],
    },
  ],
  "sim-staging": [
    {
      id: uuidv4(),
      title: "Feedback and assessment report for sim-staging",
      questions: [
        {
          question: "How is the empowerment score calculated?",
          answer:
            "The Empowerment scale evaluates a pharmacist’s recognition of the patient’s expertise in change, rewarding equal partnership and penalizing pharmacist-dominated interactions. A rating of 1 is given when the pharmacist actively assumes the expert role for the majority of the interaction with the patient. Collaboration or empowerment is absent. A rating of 5 is given when the pharmacist actively fosters and encourages power sharing in the interaction in such a way that the patient's contributions substantially influence the nature of the session.",
          id: uuidv4(),
        },
        {
          question: "How is the empathy score calculated?",
          answer:
            "The Empathy scale assesses how well a pharmacist understands and conveys the patient’s perspective, focusing on empathy distinct from sympathy or support. A rating of 1 is given when the pharmacist gives little or no attention to the patient’s perspective. A rating of 5 is given when  the pharmacist shows evidence of deep understanding of the patient’s point of view, not just for what has been explicitly stated but what the patient means but has not yet said.",
          id: uuidv4(),
        },
        {
          question: "What is the purpose of the ‘empirical questioning’ metrics?",
          answer:
            "The purpose of these metrics is to evaluate how effectively the user asked relevant and thorough questions. This helps determine the extent to which the user was able to understand the challenges faced by the patient. Additionally, these metrics assess whether the questions asked were in accordance with the established training guidelines.",
          id: uuidv4(),
        },
        {
          question:
            "What is the purpose of the ‘evidence-based interventions’ metrics?",
          answer:
            "The 'evidence-based interventions' metrics are designed to evaluate whether the user's diagnosis, actions, and prescriptions for the patient were accurate and aligned with the established guidelines.",
          id: uuidv4(),
        },
      ],
    },
    {
      id: uuidv4(),
      title: "Scores",
      questions: [
        {
          question: "What is the purpose of the insights page?",
          answer:
            "The Insights page provides a graphical representation of how your performance has changed over time. It displays trends in your scores on various standard metrics applicable to each simulation.",
          id: uuidv4(),
        },
        {
          question: "How do I know how well I am doing compared to others?",
          answer:
            "On the main landing page, you'll find your average score displayed. Directly beneath your score, look for the figure labeled `Cohort Average.` This figure represents the average score of all users participating in this training program, allowing you to compare your performance directly with others.",
          id: uuidv4(),
        },
        {
          question:
            "What should I do if I notice inaccuracies in my assessment scores?",
          answer:
            "Please note that while our AI-based tool provides assessments on various qualitative and quantitative metrics, there is a very small probability of minor inaccuracies due to drift driven by continuous improvement in our models. The primary aim of this assessment is to evaluate your performance over the long term. Over time, these scores offer an accurate reflection of your performance compared to the cohort. Additionally, the entire cohort of pharmacists going through simulations is being measured using the same methodology, ensuring consistency.",
          id: uuidv4(),
        },
      ],
    },
    {
      id: uuidv4(),
      title: "Navigating the Hale AI dashboard",
      questions: [
        {
          question: "Where can I see my performance trends over time?",
          answer:
            "Click on the ‘Insights’ tab on the top of your screen to access your timeline view.",
          id: uuidv4(),
        },
      ],
    },
    {
      id: uuidv4(),
      title: "Feedback",
      questions: [
        {
          question: "How do I give feedback?",
          answer:
            "Click on the button labeled ‘feedback’ on the top right of the page.",
          id: uuidv4(),
        },
      ],
    },
  ]
};

export default faqContent;
