import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
function convertToTwoDigit(value: number) {
  return value.toString().length > 1 ? value : `0${value}`;
}

export function getFormattedDate(
  dateStr: string | number = "",
  format: string = "{d}-{m}-{y}"
) {
  try {
    let formattedDate = format;
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthNamesFull = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "Jun",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    
    let dateToFormat = new Date();
    if (dateStr) {
      // Convert the UTC date string to local timezone
      console.log(dateStr);
      const utcDate = new Date(dateStr);
      dateToFormat = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    }
    
    const year = dateToFormat.getFullYear();
    const monthDigit = dateToFormat.getMonth() + 1;
    const month = monthNames[dateToFormat.getMonth()];
    const monthFull = monthNamesFull[dateToFormat.getMonth()];
    const date = dateToFormat.getDate();
    const day = dateToFormat.toLocaleDateString("en-us", { weekday: "short" });
    const dateHours = dateToFormat.getHours();
    const dateMinutes = dateToFormat.getMinutes();
    const dateSeconds = dateToFormat.getSeconds();
    const dateMilliSeconds = dateToFormat.getTime();
    const dateAmPm = dateHours >= 12 ? "PM" : "AM";
    const dateHours12 = dateHours % 12 || 12;
    formattedDate = formattedDate.replace("{y}", `${year}`);
    formattedDate = formattedDate.replace(
      "{yy}",
      `${year.toString().substr(2)}`
    );
    formattedDate = formattedDate.replace(
      "{md}",
      `${convertToTwoDigit(monthDigit)}`
    );
    formattedDate = formattedDate.replace("{m}", month);
    formattedDate = formattedDate.replace("{M}", monthFull);
    formattedDate = formattedDate.replace("{D}", `${day}`);
    formattedDate = formattedDate.replace("{d}", `${convertToTwoDigit(date)}`);
    formattedDate = formattedDate.replace(
      "{h}",
      `${convertToTwoDigit(dateHours12)}`
    );
    formattedDate = formattedDate.replace(
      "{H}",
      `${convertToTwoDigit(dateHours)}`
    );
    formattedDate = formattedDate.replace(
      "{i}",
      `${convertToTwoDigit(dateMinutes)}`
    );
    formattedDate = formattedDate.replace(
      "{s}",
      `${convertToTwoDigit(dateSeconds)}`
    );
    formattedDate = formattedDate.replace("{a}", dateAmPm);
    formattedDate = formattedDate.replace("{ms}", `${dateMilliSeconds}`);
    return formattedDate;
  } catch (error) {
    console.error(error);
    return null;
  }
}
